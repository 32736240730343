import { snackController } from "@/components/snack-bar/snack-bar-controller";
import Moralis from "moralis/node";

Moralis.start({
  serverUrl: process.env.VUE_APP_MORALIS_URL!,
  appId: process.env.VUE_APP_MORALIS_ID!,
});

const sleep = async (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const moralisHelper = {
  getNFTOwner: async (address: string, nftId: string, chain: "bsc" | "bsc testnet") => {
    try {
      return await Moralis.Web3API.token.getTokenIdOwners({
        chain,
        address,
        // eslint-disable-next-line
        token_id: nftId,
      });
    } catch (err) {
      console.error(err);
      snackController.error(err.error);
    }
  },

  getAllAccountNFTs: async (address: string, chain: "bsc" | "bsc testnet") => {
    let nfts: any[] = [];
    let results;
    const itemsPerPage = 100;
    let cursor: any = null;

    do {
      const response = await Moralis.Web3API.account.getNFTsForContract({
        chain,
        address,
        // eslint-disable-next-line
        token_address: process.env.VUE_APP_NFT_ADDRESS!,
        limit: itemsPerPage,
        cursor: cursor,
      });
      // if (response!.status !== "SYNCED") throw new Error("Fetch characters fail, please try again later!");

      results = response.result;
      nfts = [...nfts, ...results];

      cursor = response.cursor;
      await sleep(300);
    } while (cursor != "" && cursor != null);

    return {
      total: nfts.length,
      results: nfts,
    };
  },
  getNFTTransfers: async (address: string, nftId: string, chain: "bsc" | "bsc testnet") => {
    try {
      return await Moralis.Web3API.token.getWalletTokenIdTransfers({
        chain,
        address,
        // eslint-disable-next-line
        token_id: nftId,
        order: "block_timestamp.desc",
      });
    } catch (error) {
      console.error(error);
      snackController.error(error);
    }
  },
  getTotalMonstersInTraining: async (address: string, chain: "bsc" | "bsc testnet") => {
    try {
      const response = await Moralis.Web3API.account.getNFTsForContract({
        chain,
        address,
        // eslint-disable-next-line
        token_address: process.env.VUE_APP_NFT_ADDRESS!,
        limit: 1,
      });
      if (response!.status !== "SYNCED" || !response!.result) return 0;
      return response!.total;
    } catch (error) {
      console.error(error);
      snackController.error(error);
    }
  },
};
